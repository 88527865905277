import { ContentfulClientApi, createClient } from 'contentful';
import getConfig from './config';
import { contentfulLog } from './api/tools';

let client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>;

async function createContentfulConfig() {
  const config = await getConfig();

  return {
    space: config.CONTENTFUL_SPACE_ID,
    accessToken: config.CONTENTFUL_ACCESS_TOKEN,
    environment: config.CONTENTFUL_ENVIRONMENT,
    host: config.CONTENTFUL_DELIVERY_HOST || 'cdn.contentful.com',
    insecure: config.CONTENTFUL_INSECURE === 'true' ? true : false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    requestLogger: (request: any) => {
      contentfulLog(
        config.CONTENTFUL_ENVIRONMENT,
        config.CONTENTFUL_ACCESS_TOKEN.slice(-4),
        request?.params ?? {},
      );
    },
  };
}

export async function getClient() {
  if (!client) {
    const CLIENT_DEFAULT = await createContentfulConfig();
    client = createClient(CLIENT_DEFAULT).withoutUnresolvableLinks;
  }
  return client;
}

let previewClient: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>;

export async function getPreviewClient() {
  if (!previewClient) {
    const CLIENT_DEFAULT = await createContentfulConfig();
    const config = await getConfig();
    previewClient = createClient({
      ...CLIENT_DEFAULT,
      accessToken: config.CONTENTFUL_PREVIEW_TOKEN,
      host: 'preview.contentful.com',
    }).withoutUnresolvableLinks;
  }
  return previewClient;
}
