import { EntriesQueries, EntryQueries, EntrySkeletonType } from 'contentful';
import { TypeMicrocopySetSkeleton } from '../../../@types/generated';
import { EVENT_NAMES, sendEvent } from '../analytics';
import { getClient, getPreviewClient } from '../contentfulSetup';
import { UrlError } from '../error';
import { captureException } from '../tracking/sentry/sentry';

export const getEntry = async <T extends EntrySkeletonType>(
  entryId: string,
  query?: EntryQueries<'WITHOUT_UNRESOLVABLE_LINKS'>,
) => {
  const client = await getClient();
  return await client.getEntry<T>(entryId, query);
};

export const getEntryPreview = async <T extends EntrySkeletonType>(
  entryId: string,
  query?: EntryQueries<'WITHOUT_UNRESOLVABLE_LINKS'>,
) => {
  const client = await getPreviewClient();
  return await client.getEntry<T>(entryId, query);
};

export const getEntries = async <T extends EntrySkeletonType>(
  query?: EntriesQueries<T, 'WITHOUT_UNRESOLVABLE_LINKS'>,
) => {
  const client = await getClient();
  return await client.getEntries<T>(query);
};

export const getEntriesPreview = async <T extends EntrySkeletonType>(
  query?: EntriesQueries<T, 'WITHOUT_UNRESOLVABLE_LINKS'>,
) => {
  const client = await getPreviewClient();
  return await client.getEntries<T>(query);
};

export const getByUniqueSlug = async <T>({
  slug,
  contentType,
  include,
  tags,
  locale,
}: {
  slug: string;
  contentType: string;
  include?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  tags?: string[];
  locale?: string;
}) => {
  // add search-parameters filter while request entries,
  // [all] mean all tags need to be matched, [in] means at least one matching tag
  const tagsFilter = tags && tags.length ? { 'fields.tags[all]': tags.join(',') } : null;
  const client = await getClient();
  const entries = await client.getEntries({
    content_type: contentType,
    'fields.slug': slug,
    include,
    ...tagsFilter,
    locale,
  });

  if (!entries.items.length) {
    // we want analytics data not bug report
    sendEvent({
      name: EVENT_NAMES.notFound,
      properties: {
        type: 'contentful slug',
        data: slug,
      },
    });
    const error = new UrlError(`slug: ${slug} not found in contentful `, {
      status: 404,
    });

    throw error;
  }
  if (entries.items.length > 1) {
    throw new Error(`More than one entry found for content type ${contentType} and slug ${slug}`);
  }
  return entries.items[0] as unknown as T;
};

export async function hubSpotSubmit({
  url,
  body,
  successMessage,
  errorMessage,
}: {
  url: string;
  body: string;
  successMessage: string;
  errorMessage: string;
}) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (response.status > 299) {
      throw Error(`Error for ${url}, response status: ${response.status}`);
    }

    return successMessage;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    captureException(error);
    return errorMessage;
  }
}

export const getMicroCopy = (name: string) => {
  return getEntries<TypeMicrocopySetSkeleton>({
    content_type: 'microcopySet',
    'fields.name': name,
  }).then((entries) => {
    return entries.items?.reduce((set, item) => {
      const microcopyValues = item.fields.resources?.reduce((acc, resource) => {
        if (resource) return { ...acc, [resource.fields.key]: resource.fields.value };

        return acc;
      }, {});
      return { ...set, [item.fields.name]: microcopyValues };
    }, {});
  });
};
